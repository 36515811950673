import React from 'react';
import styled from 'styled-components';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { HeaderSmall, BaseTransparentP } from 'components/typography';
import { WidthWrapper } from 'components/wrappers';
import Inf1 from 'assets/inf-1.svg';
import Inf2 from 'assets/inf-2.svg';
import Inf3 from 'assets/inf-3.svg';
import { HoverContent } from 'components/pages/AboutUs/ReasonCard';
import fba from 'images/_fba_prep_forwarding2_.png';
import shipping from 'images/shipping2.png';
import fulfillment from 'images/fulfillment2.png';
import dropshipping from 'images/dropshipping-shipkoo-icon.png';
import crowdfunding_fulfillment from 'images/dropshipping-shipkoo-icon.png';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const Inf1Box = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Inf2Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const Inf3Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const CardContent = styled.div`
  height: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  opacity: 1;
  transition: all 0.3s ease-in;

  ${BaseTransparentP} {
    margin-top: 9px;
    line-height: 1.5;
  }

  ${HeaderSmall}{
    margin-top: 5px;
  }
`;

const ImgCard = styled.img`
  height: 100px;
  padding: 5px;
`;

const CardBody = styled.div`
  position: relative;
  border-radius: 17px;
  box-shadow: 0 23px 100px 0 rgba(153, 173, 176, 0.39);
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  .services-link {
    text-align:center;
    font-weight: 300;
    color: #9db1ad;
    line-height: 1.5;
    position: absolute;
    width: 100%;
    bottom: 20px;
    font-size: 16px;
    left:0;
  }

  .services-link:hover {
    color:#00af85;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    .services-link {
      font-size:18px;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 100000;
    box-shadow: 0 23px 100px 0 rgba(0, 0, 0, 0.39);

    ${CardContent} {
      opacity: 0;
      transition: all 0.15s ease-out;
    }

    ${HoverContent} {
      opacity: 1;
      transition: all 0.3s ease-in;
      display:block;

      ${BaseTransparentP} {
        margin-top: 9px;
        line-height: 1.6 !important;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    h3 {
      font-size:24px;
    }
  }

  svg {
    display: none;
  }

  @media ${mediaMin(breakpoint.desktop)} {

    height: 315px;

    svg {
      display: inherit;
    }
  }

  @media only screen and (max-width: 1024px){
    ${CardContent}{
      height: auto;
      padding: 10px 25px;
    }
  }
`;

const ContainerWrapper = styled.div<WidthWrapper>`
  max-width:1415px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 6001;
  @media only screen and (min-width: 1024px) {
    padding-bottom: 0px;
  }
`;

const Wrapper = styled.div`
  z-index: ${zIndex.body + 1};
  margin-bottom: 92px;
  width: 100%;

  display: grid;
  grid-gap: 20px;

  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 55px;
    padding: 30px 0 0;
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width:981px) {
    margin-bottom:30px;
  }
`;

const Characteristics = () => {
  const intl = useIntl()

  return (
    <ContainerWrapper>
      <Wrapper>
        <CardBody>
          <CardContent>
            <ImgCard src={fba} alt="FB"></ImgCard>
            <HeaderSmall>{intl.formatMessage({ id: "home-s2-column3-header" })}</HeaderSmall>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column3-subheader" })}
            </BaseTransparentP>
            <Inf3Box>
              <Inf3 />
            </Inf3Box>
          </CardContent>
          <HoverContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column3-text" })}
            </BaseTransparentP>
            <Link className="services-link" to={'/services/fba-prep-and-forwarding'}>
                {intl.formatMessage({ id: "learn-more-button" })}
            </Link>
          </HoverContent>
        </CardBody>
        <CardBody>
          <CardContent>
            <ImgCard src={shipping} alt="Shipping"></ImgCard>
            <HeaderSmall>{intl.formatMessage({ id: "home-s2-column1-header" })}</HeaderSmall>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column1-subheader" })}
            </BaseTransparentP>
            <Inf1Box>
              <Inf1 />
            </Inf1Box>
          </CardContent>
          <HoverContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column1-text" })}
            </BaseTransparentP>
            <Link className="services-link" to={'/services/express-shipping'}>
                {intl.formatMessage({ id: "learn-more-button" })}
            </Link>
          </HoverContent>
        </CardBody>
        <CardBody>
          <CardContent>
            <ImgCard src={fulfillment} alt="Fulfillment"></ImgCard>
            <HeaderSmall>{intl.formatMessage({ id: "home-s2-column2-header" })}</HeaderSmall>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column2-subheader" })}
            </BaseTransparentP>
            <Inf2Box>
              <Inf2 />
            </Inf2Box>
          </CardContent>
          <HoverContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column2-text" })}
            </BaseTransparentP>
            <Link className="services-link" to={'/services/ecommerce-fulfillment'}>
               {intl.formatMessage({ id: "learn-more-button" })}
            </Link>
          </HoverContent>
        </CardBody>

        <CardBody>
          <CardContent>
            <ImgCard src={crowdfunding_fulfillment} alt="crowdfunding fulfillment"></ImgCard>
            <HeaderSmall>{intl.formatMessage({ id: "crowdfunding-fulfillment-col-title" })}</HeaderSmall>
            <BaseTransparentP>
              {intl.formatMessage({ id: "crowdfunding-fulfillment-col-desc" })}
            </BaseTransparentP>
            <Inf3Box>
              <Inf3 />
            </Inf3Box>
          </CardContent>
          <HoverContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "crowdfunding-fulfillment-col-hover-desc" })}
            </BaseTransparentP>
            <Link className="services-link" to={'/services/crowdfunding-fulfillment'}>
                {intl.formatMessage({ id: "learn-more-button" })}
            </Link>
          </HoverContent>
        </CardBody>
        {/*<CardBody>
          <CardContent>
            <ImgCard src={dropshipping} alt="dropshipping"></ImgCard>
            <HeaderSmall>{intl.formatMessage({ id: "home-s2-column4-header" })}</HeaderSmall>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column4-subheader" })}
            </BaseTransparentP>
            <Inf3Box>
              <Inf3 />
            </Inf3Box>
          </CardContent>
          <HoverContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "home-s2-column4-text" })}
            </BaseTransparentP>
          </HoverContent>
        </CardBody>*/}
      </Wrapper>
    </ContainerWrapper>
  );
};

export default Characteristics;

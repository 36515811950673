import React, { useEffect } from "react";
import Home from 'components/pages/Home';
import Layout from 'components/Layout';
import Helmet from "react-helmet"
import { navigate } from "gatsby";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const IndexPage = () => {
  const intl = useIntl()

  return (

  <Layout>
    <Helmet>
      <title>{intl.formatMessage({ id: "title" })}</title>
      
      <link rel="alternate" href={intl.formatMessage({ id: "home-link" })} hreflang={intl.formatMessage({ id: "language" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "home-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "home-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "home-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
      <meta name="description" content={intl.formatMessage({ id: "description" })} />
      <meta property="og:title" content={intl.formatMessage({ id: "title" })} />
      <meta property="og:description" content={intl.formatMessage({ id: "description" })} />
      <meta name="twitter:title" content={intl.formatMessage({ id: "title" })} />
      <meta name="twitter:description" content={intl.formatMessage({ id: "description" })} />
      <meta name="facebook-domain-verification" content="6zo9t35oqcd56dvpqc0msll9284td8" />
    </Helmet>
    <Home />
  </Layout>
  );
};

export default IndexPage;

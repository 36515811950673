import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { BaseP, BaseTransparentP, HeaderMedium } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, color } from 'common/styledVars';
import ArrowButton from 'components/Button/ArrowButton';
import {
  Wrapper,
  FullImageBox,
  GatsbyImage,
  SvgWrapper,
  DownLink,
  MobileReasons,
  MobileReasonsBox,
} from './styled';
import Characteristics from './Characteristics';
import ShipkooWhySvg from 'assets/all.svg';
import { ArrowIcon } from 'components/icons';
import AiDriven from 'assets/ai-driven.svg';
import FreeSaas from 'assets/free-saas.svg';
import GlobalCov from 'assets/global-cov.svg';
import LowerTaxes from 'assets/lower-taxes.svg';
import MinInventory from 'assets/minimize-inventory.svg';
import OnlineBussines from 'assets/online-business.svg';
import SaveShipping from 'assets/save-on-shipping.svg';
import styled from 'styled-components';
import global_coverage from 'images/global_coverage2.png';
import free_saas from 'images/free_saas_solution2.png';
import driven from 'images/driven_by_ai2.png';
import taxes from 'images/lower_taxes_and_duties2.png';
import online_business from 'images/online_bussness2.png';
import minimize_inventory from 'images/minimize_inventory2.png';
import save_money from 'images/save_money_on_shipping2.png';
import save_time from 'images/save-time.png';
import AllInOne from '../all-in-one';
import OurPartnersSlider from '../OurPartners'

const HeaderSmall = styled.div`

  opacity: 0.9;
  font-size: 21px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 26px;
  }
`;

const WhyShipkooDiv = styled.div`
    text-align: center;
    padding-top: 45px;
    padding-bottom: 35px;

    .column {
      display: inline-block;
      vertical-align: top;
      padding: 15px 20px;
      width: 25%;
    }

    .row:after {
      content: "";
      display: table;
      clear: both;
    }

    .row {
      display: inline-block !important;
      width: 100%;
      max-width: 1080px;
    }

    ${HeaderSmall} {
      font-size: 18px;
    }

    ${HeaderMedium} {
      margin-bottom: 20px;
    }

    @media only screen and (max-width:981px) {
      ${HeaderMedium}{
        margin-bottom: 0;
      }
      padding-bottom: 0;
    }

    @media only screen and (min-width:1024px) {
      ${HeaderMedium}{
        margin-bottom: 0;
      }
      padding-top: 70px;
      padding-bottom: 40px;
    }

    @media
    only screen and (max-width: 760px),
    (min-width: 472px) and (max-width: 1024px)  {
      .column {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
      }

      .column img {
        max-width: 70px;
        height: auto;
      }

      /*.last .column:last-child {
        width: 100%;
      }*/

      .column > div {
        font-size: 16px;
      }

      .row {
        width: 100%;
      }

      .mt-30-mobile {
        margin-top: 30px;
      }
    }

`;

const Images = styled.img`
  height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom 15px;
  max-width: 70px;
  height: auto;
`;

const HeaderLarge = styled.h2`
  font-size:28px;
  margin: 80px 0 40px;
  /*margin: 140px 0 40px;*/
  max-width: 920px;
  font-weight:500;

  @media only screen and (min-width: 1024px) {
    font-size: 48px;
    line-height: 61px;
  }

  @media only screen and (max-width:768px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }

`;


const AboutCompany = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgContenerQuery {
      file(relativePath: { eq: "bg2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <RelativeWrapper>
        <FullImageBox>
          <GatsbyImage alt="shipkoo ecommerce international aerial shipping solutions" fluid={data.file.childImageSharp.fluid} />
        </FullImageBox>
        <Wrapper>
          <HeaderLarge>
            {intl.formatMessage({ id: "home-s2-header" })}
          </HeaderLarge>
          <BaseP>{intl.formatMessage({ id: "home-s2-subheader" })}</BaseP>
          <BaseTransparentP>
            {intl.formatMessage({ id: "home-s2-text" })}
          </BaseTransparentP>
          <Link to="/our-story/">
            <ArrowButton>{intl.formatMessage({ id: "learn-more-button" })}</ArrowButton>
          </Link>
        </Wrapper>
        <Characteristics />
      </RelativeWrapper>
      <OurPartnersSlider />
      <AllInOne />
      <WidthWrapper>
        <WhyShipkooDiv>
          <HeaderMedium>
            {intl.formatMessage({ id: "home-s3-header" })}
          </HeaderMedium>
          <Wrapper>
            <div className="row mt-30-mobile">
              <div className="column">
                <Images src={global_coverage} alt="Global Coverage" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column1-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={free_saas} alt="Free SaaS Solution" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column2-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={taxes} alt="Lower Taxes & Duties" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column3-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={driven} alt="AI-Driven" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column4-header" })}</HeaderSmall>
              </div>
            </div>
            <div className="row last">
              <div className="column">
                <Images src={save_money} alt="Save Money on Shipping" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column5-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={online_business} alt="Expertise on Online Business" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column6-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={minimize_inventory} alt="Minimize Inventory" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column7-header" })}</HeaderSmall>
              </div>
              <div className="column">
                <Images src={save_time} alt="Save Time" />
                <HeaderSmall>{intl.formatMessage({ id: "home-s3-column8-header" })}</HeaderSmall>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <Link to="/our-story">
              <ArrowButton>{intl.formatMessage({ id: "more-info-button" })}</ArrowButton>
            </Link>
          </Wrapper>
        </WhyShipkooDiv>
      </WidthWrapper>
    </>
  );
};

export default AboutCompany;

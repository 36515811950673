import React from 'react';

import Intro from './Intro';
import AboutCompany from './AboutCompany';
import Partners from './Partners';
import ReachUs from './ReachUs';
import AllInOne from './all-in-one';
import EcommerceBrandsSlider from './EcommerceBrands'
import HomePopup from './HomePopup';

const IndexPage = () => (
  <>
    <Intro />
    <EcommerceBrandsSlider />
    <AboutCompany />
    <Partners />
    <ReachUs />
    <HomePopup />
  </>
);

export default IndexPage;

import React, { useState, useEffect, useCallback, RefObject, useRef, createRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { breakpoint } from 'common/styledVars';
import { mediaMin } from 'common/styledMixins';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Input from 'components/Input';
import ArrowButton from 'components/Button/ArrowButton';
import { Wrapper, HalfBox, Box, BoxSelect, ErrorMessage } from '../../../components/pages/Contact/ContactForm/form-styles';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from "react-helmet";

import './home-popup.css'

const ERROR_MESSAGE = 'Server Error, message cannot be send. Please contact us by phone.';

const ButtonContainer = styled.div`

    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    

    button {
        width: 100%;
        text-align: center;
        display: block;
        font-size: 18px;
        height: 45px;
        border-radius: 10px;
        margin-top: 8px !important;
        background-color: rgb(0, 175, 133);
        color: #fff;
    }

    button:hover {
        background-color: rgb(0, 175, 133);
        color: #fff;
    }

    svg {
      display: none;
    }

    .en .zh {
      display:none;
    }

    .en .kr {
      display:none;
    }

    .en .ja {
      display:none;
    }

    .zh .en {
      display:none;
    }

    .zh .kr {
      display:none;
    }

    .zh .ja {
      display:none;
    }

    .kr .en {
        display:none;
    }

    .kr .zh {
        display:none;
    }

    .kr .ja {
        display:none;
    }

    .ja .en {
        display:none;
    }

    .ja .zh {
        display:none;
    }

    .ja .kr {
        display:none;
    }

    @media only screen and (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
`;

const Select = styled.select`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  border: 3px solid #EFF0F2;
  text-transform: capitalize;
  padding: 6px 15px;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;

  &::placeholder {
    opacity: 0.3;
  }

  &:focus {
    opacity: 1;
  }
`;

export const BoxSelectDiv = styled.div`
  flex: 1 100%;
  margin-bottom: 30px;
  max-width: 100% !important;
  padding-left: 10px;
  padding-right: 10px;
  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 10px !important;
  }
  #hidden_input {
    display: none;
  }

  @media only screen and (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Modal = styled.div`
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    max-width: 624px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 999999;
    background: #fff;
    border-radius: 5px;
    padding: 30px 20px;
    // height: 690px;
    // overflow-y: scroll;


    ::-webkit-scrollbar {
        // Width of vertical scroll bar
        width: 8px;
        // Height of horizontal scroll bar
        height: 10px;
    
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #c2c9d2;
      }

    @media only screen and (min-width: 1536px) {
        height: 720px;
        overflow-y: scroll;
    }

    @media only screen and (max-width: 1396.36px) {
        height: 615px;
        overflow-y: scroll;
    }

    @media only screen and (max-width: 1024px) {
        height: 550px;
        overflow-y: scroll;
    }

    @media only screen and (max-width: 1280px) {
        height: 550px;
        overflow-y: scroll;
    }

    

    @media only screen and (max-width: 500px) {
        max-width: 340px;
    }

    @media only screen 
    and (min-width: 320px) 
    and (max-width: 845px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {
        height: 250px;
    }

`;

const BoxDiv = styled.div`
    flex: 1 100%;
    margin-bottom: 10px;
    max-width: 100% !important;
    padding-left: 10px;
    padding-right: 10px;
    #hidden_input {
        display: none;
    }

    @media only screen and (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10px;
    }
`;

const HalfBoxDiv = styled.div`
    flex: 1 50%;
    max-width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    @media ${mediaMin(breakpoint.desktop)} {
    max-width: 50% !important;
    }

    @media only screen and (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
    }

`;

const ModalBody = styled.div`

`;

const ModalHeader = styled.div`
    h2 {
        color: rgb(0, 175, 133);
        margin-bottom: 15px;
        margin-left: 10px;
    }

    display: flex;
    justify-content: space-between;

    button {
        background: transparent;
        border: none;
        color: #fff;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    @media only screen and (max-width: 1024px) {
        h2 {
            font-size: 18px;
            margin-bottom: 20px;
            margin-left: 0px;
        }
    }
    
`;

const ModalWrapper = styled.div`
    width: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.8);
    z-index: 99999;
    height: 100%;
    top: 0;
    bottom: 0;
`;

const FormWrapper = styled.form`
    display: flex;
    flex-wrap: wrap;
    input {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        border: 3px solid #EFF0F2;
        padding: 6px 15px;
        border-radius: 5px;
        height: 35px;
        width: 100%;
        background: transparent;
        margin-bottom: 10px;
        opacity: 1;
    }

    textarea {
        width: 100%;
        height: 110px;
        border: 3px solid #EFF0F2;
        padding: 6px 15px;
        border-radius: 5px;
    }

    textarea::placeholder {
        color: #000;
        opacity: 1;
    }

    .kIPotU {
        margin-bottom: 0px!important;
    }

    Input__LabelWrapper-sc-1fr5n1y-3 eqgqcD {
        display: none;
    }

    // .mb-0 {
    //     margin-bottom: 0;
    // }

    // .phone-input {
    //     margin-top: 12px;
    // }

    @media only screen and (max-width: 640px) {
        display: block;
    }

`;


interface FormData {
    company_name: string;
    name: string;
    email: string;
    phone: string;
    message: string;
}

interface OwnProps {
    innerRef: RefObject<any>;
    rows: RefObject<any>;
    cols: RefObject<any>;
    textareaValue: RefObject<any>;
    limit: RefObject<any>;
}

const HomePopup = ({ innerRef, rows, cols, textareaValue, limit }: OwnProps) => {

    const intl = useIntl()
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            functionEndpoint
          }
        }
      }
    `,
    );
    const { register, handleSubmit, errors } = useForm<FormData>();
    const getErrors = (name: keyof FormData) => errors[name] && errors[name]?.message;
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isCountryOther, setIsCountryOther] = useState("");
    const [value, setValue] = useState();
    const [currentCountryCode, setCurrentCountryCode] = useState(null);
    const [messageVal, setMessageVal] = useState("");
    const [charCount, setCharCount] = useState(0);
    const TEST_SITE_KEY = "6LcXb2khAAAAAKX6vGijKqjBsaiAkE_dOWYZMxlQ";
    const [CaptchaValue, setCaptchaValue] = useState("[empty]");
    const [callback, setCallBack] = useState("not fired");
    const [expired, setExpired] = useState(false);
    const _reCaptchaRef = createRef();
    const site_key = "7fe715a1-151f-4c63-b497-bd971974df05";

    const [isModalShown, setIsModalShown] = useState(true);

    const handleCaptchaChange = value => {
        console.log("Captcha value:", value);
        setCaptchaValue(value);
        // if value is null recaptcha expired
        if (value === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallBack("called!");
    };

    // useEffect(() => {
    //   console.log(window.gtag);
    // }, [])

    function checkFromCountry(e) {
        setIsCountryOther(e.target.value);
        console.log("Country From:", isCountryOther);
    }

    const getCountryCode = async () => {
        const detectCountryCode = await axios.get('https://ipwho.is/')
        setCurrentCountryCode(detectCountryCode.data.country_code);
    }

    function handleConversion() {
        window.gtag("event", "conversion", { send_to: ["AW-10884171449/D66TCLKeqYEYELmN_cUo"] });
        // console.log("Handle Conversion", window.gtag);
    }

    function checkForm(e) {

        if (charCount >= 25 && value && isPossiblePhoneNumber(value)) {
            // do the action in form
            // alert ("fields valid");

        } else if (charCount < 25) {
            alert("Message should be Minimum of 25 Characters/Words");
            e.preventDefault();
        } else if (value && isPossiblePhoneNumber(value) == false) {
            alert("Please enter a valid phone number");
            e.preventDefault();
        } else {
            alert("An error occured please try again");
            e.preventDefault();
        }

    }

    function hideModal(e) {
        setIsModalShown(false);
        e.stopPropagation();
    }

    function hideModal(e) {
        setIsModalShown(false);
        e.stopPropagation();
    }


    const countWords = (e) => {
        const text = e.target.value
        setMessageVal(text);
        const stringText = text.toString();
        if (text == '') {
            setCharCount(0);
        } else {
            setCharCount(stringText.length);
        }

    }

    useEffect(() => {
        getCountryCode();
    }, [currentCountryCode])

    const onSubmit = useCallback((data: FormData) => {
        setLoading(true);
        setError('');
        fetch(site.siteMetadata.functionEndpoint, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then(response => {
                if (response.status === 500) {
                    setError(ERROR_MESSAGE);
                    setSubmitted(true);
                } else if (response.status === 400) {
                    setError('Form Error, please check if provided data is correct');
                } else {
                    setSubmitted(true);
                }
            })
            .catch(() => setError(ERROR_MESSAGE))
            .finally(() => {
                setLoading(false);
            });
    }, [site.siteMetadata.functionEndpoint]);

    return (

        /*<p>onSubmit={handleSubmit(onSubmit)}</p>*/
        <>
            <Helmet>
                <body className={isModalShown ? "modal-open" : ""} />
                {/* <script>
                {`
                    var widgetPopup;
                    var onloadCallbackPopup = function() {
                        if (widgetPopup == undefined) {
                            widgetPopup = grecaptcha.render(document.getElementById('g-recaptcha-popup'), {
                                "sitekey" : "6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"
                            });
                        }
                    };
                `}
            </script>
                <script
                    
                    src={`https://www.google.com/recaptcha/api.js?onload=onloadCallbackPopup&render=explicit`}
                    async
                    defer
                /> */}
            </Helmet>
            {isModalShown &&
                <ModalWrapper>
                    <Modal>
                        <ModalBody >
                            <ModalHeader>
                                <h2>{intl.formatMessage({ id: "c-contact-subheader" })}</h2>
                                <button onClick={hideModal}><svg stroke="currentColor" fill="currentColor" stroke-width="2" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-width="2" d="M3,3 L21,21 M3,21 L21,3"></path></svg></button>
                            </ModalHeader>
                            <FormWrapper action="https://usebasin.com/f/216294d39461" method="post" accept-charset="UTF-8" onSubmit={checkForm} id="home-form-popup">
                                <input type="hidden" name="_shipkoo" />
                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-first-name-label" })}</label>
                                    <Input
                                        name="first_name"
                                        ref={register({ required: 'Name is required', maxLength: 80 })}
                                        error={getErrors('name')}
                                    />
                                </HalfBoxDiv>
                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-last-name-label" })}</label>
                                    <Input
                                        // placeholder="Last Name"
                                        name="last_name"
                                        ref={register({ required: 'Name is required', maxLength: 80 })}
                                        error={getErrors('name')}
                                    />
                                </HalfBoxDiv>
                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-email-label" })}</label>
                                    <Input
                                        // placeholder={intl.formatMessage({ id: "c-contact-form-input4-label" })}
                                        type="email"
                                        name="email"
                                        ref={register({ required: 'E-mail is required', pattern: /^\S+@\S+$/i })}
                                        error={getErrors('email')}
                                    />
                                </HalfBoxDiv>
                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-mobile-label" })}</label>
                                    <PhoneInput
                                        // placeholder={intl.formatMessage({ id: "c-contact-form-input3-label" })}
                                        international
                                        countryCallingCodeEditable={false}
                                        value={value}
                                        onChange={setValue}
                                        defaultCountry={currentCountryCode}
                                        error={value ? (isPossiblePhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number'}
                                        name="phone"
                                        className="phone-input"
                                        required
                                    />
                                    {/* Is possible: {value && isPossiblePhoneNumber(value) ? 'true' : 'false'} */}
                                </HalfBoxDiv>
                                <BoxDiv ref={innerRef}>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-company-name-label" })}</label>
                                    <Input
                                        // placeholder={intl.formatMessage({ id: "c-contact-form-input1-label" })}
                                        ref={register({ required: 'Company name is required', maxLength: 30 })}
                                        name="company_name"
                                        error={getErrors('company_name')}
                                    />
                                </BoxDiv>
                                <BoxSelectDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-transport-label" })}</label>
                                    <Select name="mode_of_transport" className="dropdowns" ref={register({ required: 'Mode of Transport is required' })} error={getErrors('mode_of_transport')} required>
                                        {/* <option hidden value="">Mode of Transport</option> */}
                                        <option value={intl.formatMessage({ id: "c-contact-popup-transport-option-lcl" })} selected>{intl.formatMessage({ id: "c-contact-popup-transport-option-lcl" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-transport-option-fcl" })}>{intl.formatMessage({ id: "c-contact-popup-transport-option-fcl" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-transport-option-other" })}>{intl.formatMessage({ id: "c-contact-popup-transport-option-other" })}</option>
                                    </Select>
                                </BoxSelectDiv>
                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-from-label" })}</label>
                                    <Select className={isCountryOther ? "mb-0" : ""} name="from" ref={register({ required: 'From Country is required' })} error={getErrors('from')} required onChange={checkFromCountry}>
                                        {/* <option hidden value=""></option> */}
                                        <option value={intl.formatMessage({ id: "c-contact-popup-from-option-china" })} selected>{intl.formatMessage({ id: "c-contact-popup-from-option-china" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-from-option-other" })}>{intl.formatMessage({ id: "c-contact-popup-from-option-other" })}</option>
                                    </Select>
                                </HalfBoxDiv>

                                <HalfBoxDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-popup-to-label" })}</label>
                                    <Select name="to" ref={register({ required: 'To Country is required' })} error={getErrors('to')} required>
                                        {/* <option hidden value="">To:</option> */}
                                        <option value={intl.formatMessage({ id: "c-contact-popup-to-option-uk" })}>{intl.formatMessage({ id: "c-contact-popup-to-option-uk" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-to-option-usa" })}>{intl.formatMessage({ id: "c-contact-popup-to-option-usa" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-to-option-canada" })}>{intl.formatMessage({ id: "c-contact-popup-to-option-canada" })}</option>
                                        <option value={intl.formatMessage({ id: "c-contact-popup-to-option-europe" })}>{intl.formatMessage({ id: "c-contact-popup-to-option-europe" })}</option>
                                    </Select>
                                </HalfBoxDiv>
                                {isCountryOther == "Others" || isCountryOther == "其他" || isCountryOther == "その他" || isCountryOther == "다른이들" ?
                                    <BoxDiv style={{ marginBottom: "20px", marginTop: "0px" }}>
                                        <p><b>{intl.formatMessage({ id: "c-contact-popup-from-other-specify" })}</b></p>
                                    </BoxDiv>
                                    :
                                    null
                                }
                                <BoxDiv>
                                    <BoxSelectDiv style={{paddingLeft:"0", paddingRight: "0", marginTop: "5px"}}>
                                        <Select name="service_name" ref={register({ required: 'Type of Service is Required' })} error={getErrors('service_name')} required>
                                            <option hidden value="">{intl.formatMessage({ id: "home-contact-service-type-label" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-fba-prep" })}>{intl.formatMessage({ id: "menu-fba-prep" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-express-shipping" })}>{intl.formatMessage({ id: "menu-express-shipping" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-ecommerce-fulfillment" })}>{intl.formatMessage({ id: "menu-ecommerce-fulfillment" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-last-mile-delivery" })}>{intl.formatMessage({ id: "menu-last-mile-delivery" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-sourcing" })}>{intl.formatMessage({ id: "menu-sourcing" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-amazon-distribution-center" })}>{intl.formatMessage({ id: "menu-amazon-distribution-center" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-us-customes-clearance" })}>{intl.formatMessage({ id: "menu-us-customes-clearance" })}</option>
                                            <option value={intl.formatMessage({ id: "menu-sourcing-from-china2" })}>{intl.formatMessage({ id: "menu-sourcing-from-china2" })}</option>
                                        </Select>
                                    </BoxSelectDiv>
                                    <label>{intl.formatMessage({ id: "c-contact-form-input5-label" })}</label>
                                    <textarea
                                        placeholder={intl.formatMessage({ id: "c-contact-form-input5-label" })}
                                        name="message"
                                        ref={register({ required: 'Please fill the message', maxLength: 500 })}
                                        error={getErrors('message')}
                                        onChange={countWords}
                                        className="textarea-message"
                                        required
                                    />
                                    {/* <button onClick={countWords}>Count Words</button> */}
                                    {/* <p>{charCount} characters.</p> */}
                                </BoxDiv>
                                {/* <BoxDiv>
                                    <div className="g-recaptcha" id="g-recaptcha-popup"></div>
                                </BoxDiv> */}
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                <ButtonContainer>
                                    <div className={intl.formatMessage({ id: "language" })}>
                                        <div className="en">
                                            <ArrowButton type="submit" disabled={submitted} className="send-form">
                                                {/* <ArrowButton type="submit" disabled={submitted} className="send-form" > */}
                                                {submitted ? 'Message Sent' : loading ? 'Loading...' : 'Send Message'}
                                            </ArrowButton>
                                        </div>
                                        <div className="zh">
                                            <ArrowButton type="submit" disabled={submitted} className="send-form">
                                                {/* <ArrowButton type="submit" disabled={submitted} className="send-form"> */}
                                                {submitted ? '消息发送' : loading ? 'Loading...' : '发送消息'}
                                            </ArrowButton>
                                        </div>
                                        <div className="kr">
                                            <ArrowButton type="submit" disabled={submitted} className="send-form">
                                                {/* <ArrowButton type="submit" disabled={submitted} className="send-form"> */}
                                                {submitted ? '보낸 메시지' : loading ? 'Loading...' : '메시지 보내기'}
                                            </ArrowButton>
                                        </div>
                                        <div className="ja">
                                            <ArrowButton type="submit" disabled={submitted} className="send-form">
                                                {/* <ArrowButton type="submit" disabled={submitted} className="send-form"> */}
                                                {submitted ? 'メッセージを送信しました' : loading ? '読み込んでいます...' : 'メッセージを送る'}
                                            </ArrowButton>
                                        </div>
                                    </div>
                                </ButtonContainer>

                            </FormWrapper>
                        </ModalBody>
                    </Modal>
                </ModalWrapper>
            }
        </>
    );
};

export default HomePopup;

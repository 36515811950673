import React from 'react';
import styled from 'styled-components';

import { color, zIndex } from 'common/styledVars';
import { BaseP, ScholarHeader } from 'components/typography';
import { ArrowIcon } from 'components/icons';

interface ColorProps {
  color: string;
}

const BaseContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  text-align: 20;

  ${ScholarHeader} {
    margin-bottom: 9px;
  }

  ${ArrowIcon} {
    width: 11px;
    height: 12px;
    transform: rotate(-90deg);
    g {
      fill: ${props => props.color};
    }
  }
`;

export const HoverContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding: 25px;
  display: flex;
  align-items: center;

  ${BaseP} {
    color: #9db1ad;
  }
`;

const Container = styled.div<ColorProps>`
  position: relative;
  height: 178px;
  padding: 0 43px 0 47px;
  background-color: ${color.white};
  box-shadow: 0px 5px 5px 6px #e6edef;
  z-index: ${zIndex.body + 5};
  border-radius: 17px;
  display: flex;
  align-items: center;

  transition: all 0.2s ease-in-out;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  ${BaseContent} {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 100000;

    ${BaseContent} {
      opacity: 0;
      transition: all 0.15s ease-out;
    }

    ${HoverContent} {
      opacity: 1;
      transition: all 0.3s ease-in;
    }
  }

  img {
    height:auto;
    width: 80px;
    margin-right: 20px;
  }
`;

interface OwnProps {
  title: string;
  text: string;
  color: string;
  hover: string;
  imgSrc: string;
}

const ReasonCard = ({ title, text, color, hover, altText, imgSrc }: OwnProps) => (
  <Container color={color}>
    <BaseContent>
      <img src={imgSrc} alt={altText} />
      <div>
        <ScholarHeader>{title}</ScholarHeader>
        <BaseP>{text}</BaseP>
      </div>
    </BaseContent>
    <HoverContent>
      <BaseP>{hover}</BaseP>
    </HoverContent>
  </Container>
);

export default ReasonCard;

import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { BaseP, HeaderMedium } from 'components/typography';
import ArrowButton from 'components/Button/ArrowButton';
import { mainContainerWidth } from 'common/styledVars';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import VideoBG from './video_background.jpg';
import ShipkooPoster from './video-poster.jpg';
import Icon1 from 'images/icon1_how_it_works.png';
import Icon2 from 'images/icon2_how_it_works.png';
import Icon3 from 'images/icon3_how_it_works.png';
import ShipkooVideo from 'images/shipkoo-video-thumbnail.png';
import ContactForm from 'components/pages/Contact/ContactForm/';
import ContactFormSection from 'components/pages/Contact/ContactFormSectionHome'

const VideoImage = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video-container" id="shipkoo_video">
    <Link to="/our-story/">
      <img src={ShipkooVideo} alt="How We Can Help You" />
    </Link>
  </div>
)

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video-container" id="shipkoo_video">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      width="640"
      height="340"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

const CTAHeader = styled.h4`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 45px;

  @media only screen and (min-width: 1024px) {
    font-size: 36px;
  }
`;

const Container = styled.div`
  margin: 30px auto;
  max-width: ${mainContainerWidth.desktop + 30}px;
  width: 100%;
  display: flex;
  padding: 0 15px;
  z-index: 9;

  @media only screen and (max-width: 981px) {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 981px) {
    margin-top: 60px;
    margin-bottom: 0;
  }

  form {
    margin-top: 50px !important;
    margin-bottom: 20px !important;
    max-width: 700px;
  }

  form div {
    margin-bottom: 20px;
  }

  form div div {
    margin-bottom: 0;
  }

  form button {
    margin-top: 0 !important;
  }
  form textarea {
    margin-bottom: -30px;
  }

  form input, form textarea {
    border: 1px solid rgb(0, 175, 133);
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    opacity: 1;
  }
`;

const ImgWrapper = styled.div`
  width: 55%;
  background-image: url('/icons/infinity-3.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const TextWrapper = styled.div`
  flex: 1;
  margin-right: 115px;

  .reach-us-header {
    margin-bottom: 45px;
  }

  .reach-us-header {
    font-size: 14px;
    font-weight: 300;
    color: #00AF85;
  }

  @media only screen and (min-width: 1024px) {
    .reach-us-header {
      font-size: 16px;

    }
  }

  @media only screen and (max-width: 981px) {
    margin-right:0;
  }

  ${HeaderMedium} {
    margin-bottom: 45px;
  }
`;

const MainWrapper = styled(RelativeWrapper)`
  .column {
    float: left;
    width: 49%;
    margin-right:2%;
    padding: 10px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .column.last {
    margin-right:0 !important;
  }

  #how-it-works {
    max-width: 1255px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    padding-top: 0;
    position: relative;
  }
  #how-it-works .hiw-title {
    font-size: 36px;
    margin-bottom: 35px;
    line-height: 45px;
    font-family: CircularStd;
    margin-left: 60px;
    font-weight: 500;
    margin-left: 0;
  }
  #how-it-works .hiw-list {
    font-size:20px;
    font-weight:500;
    margin-bottom:0px;
  }
  #how-it-works .text-desc{
    font-size:15px;
    line-height:30px;
    margin-bottom:30px;
  }
  #how-it-works .text-desc-container {
      width: 89%;
      display: inline-block;
      float: left;
  }

  #how-it-works .img-container {
    display: inline-block;
    width: 9%;
    margin-right:2%;
    float: left;
  }

  #how-it-works .img-container img {
    max-width: 45px;
    width:100%;
  }

  #how-it-works .list-column{
      padding-bottom:20px;
  }

  #shipkoo_video {
    -webkit-box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    width:100%;
    margin-top:10px;
    border:6px solid #888989;
    transition: transform .5s ease;
  }

  #shipkoo_video:hover {
    transform: scale(1.02);
  }

  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
  }

  /*.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }*/

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #section-background {
    position: absolute;
    width: 100%;
  }

  #section-background img {
    max-height: 650px;
    width: 100%;
  }

  .section-background-container {
    position: relative;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  .clear {
    clear: both;
  }

  #how-it-works-section {
    margin-bottom: 0;
  }

  @media only screen and (max-width:768px) {
    .column {
      width:100% !important;
      margin-right:0 !important;
      float:none;
    }

    #how-it-works {
      padding-left: 20px;
      padding-right: 20px;
    }

    #how-it-works .hiw-title {
      font-size: 28px !important;
      margin-left:0 !important;
      text-align:center;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    #how-it-works .text-desc {
      margin-bottom: 0;
    }

    #how-it-works {
      padding-top: 20px;
    }

    #section-background {
      display:none;
    }

    #how-it-works-section {
      margin-top: 0 !important;
      margin-bottom: -60px !important;
    }

  }
  @media only screen and (max-width:425px) {
    #how-it-works .img-container {
      width: 100% !important;
      margin-bottom: 20px !important;
      margin-right: 0 !important;
      float: none !important;
      display: block !important;
    }

    #how-it-works .img-container img {
      margin-left: auto;
      margin-right: auto;
    }

    #how-it-works .text-desc-container {
      text-align: center !important;
      width: 100% !important;
      float: none !important;
      display: block !important;
    }

    #shipkoo_video {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const ReachUs = () => {
  const intl = useIntl()
  return (
    <MainWrapper>
        <div className="section" id="how-it-works-section">
           <div id="section-background">
            <div className="section-background-container">
              <img src={VideoBG} alt="Halftone Background" />
            </div>
          </div>
          <div className="row" id="how-it-works">
            <div className="column">
              <VideoImage
                videoSrcURL="https://www.youtube.com/embed/c60Nh8Rq_Q8"
                videoTitle="Introduction to Shipkoo - Be Cool Ship Globally"
              />
            </div>
            <div className="column last">
              <h4 className="hiw-title">{intl.formatMessage({ id: "home-hiw-header" })}</h4>
              <div className="list-column">
                <div className="img-container">
                  <img src={Icon1} alt="Cargo" />
                </div>
                <div className="text-desc-container">
                  <div className="hiw-list">{intl.formatMessage({ id: "home-hiw-subheader1" })}</div>
                  <p className="text-desc">{intl.formatMessage({ id: "home-hiw-text1" })}</p>
                </div>
                <div className="clear"></div>
              </div>
              <div className="list-column">
                <div className="img-container">
                  <img src={Icon2} alt="Computer Desktop" />
                </div>
                <div className="text-desc-container">
                  <div className="hiw-list">{intl.formatMessage({ id: "home-hiw-subheader2" })}</div>
                  <p className="text-desc">{intl.formatMessage({ id: "home-hiw-text2" })}</p>
                </div>
                <div className="clear"></div>
              </div>
              <div className="list-column">
                <div className="img-container">
                  <img src={Icon3} alt="Money" />
                </div>
                <div className="text-desc-container">
                  <div className="hiw-list">{intl.formatMessage({ id: "home-hiw-subheader3" })}</div>
                  <p className="text-desc">{intl.formatMessage({ id: "home-hiw-text3" })}</p>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      <ContactFormSection />
      {/*<Container>
        
        <TextWrapper>
          <h3 className="reach-us-header">
            {intl.formatMessage({ id: "home-cta-subheader" })}
          </h3>
          <CTAHeader>{intl.formatMessage({ id: "home-cta-header" })}</CTAHeader>
          <Link to="/contact">
            <ArrowButton >{intl.formatMessage({ id: "home-cta-button" })}</ArrowButton>
          </Link>
        </TextWrapper>
        <ContactForm />
      </Container>*/}
    </MainWrapper>
  );
};

export default ReachUs;

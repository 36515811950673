import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { WidthWrapper, RelativeWrapper } from 'components/wrappers';
import ArrowButton from 'components/Button/ArrowButton';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import HeroImage from 'images/home_hero.png'
import HeroImage2 from 'images/home_hero2.png'

export const ImgGatsbyBackground = styled(Img)`
  position: absolute;
  left: calc(50% - 50px);
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${zIndex.body};
`;

const Wrapper = styled.div`
  padding: 20px 0;

  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 45%;
    padding: 10px;
  }

  .home-hero-image2 {
    margin-top: 35px;
    max-width: 467px;
  }

  .column.last {
    width: 55%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  h1 {
    color: #000000;
    font-family: "CircularStd";
    line-height: 1.5;
    font-size: 28px;
    font-weight:500;
    max-width: 490px;
  }

  h2 {
    color: #000000;
    font-family: "CircularStd";
    line-height: 1.5;
    font-size: 28px;
    font-weight:500;
    max-width: 490px;
  }

  ${HeaderMedium} {
    max-width: 460px;
  }

  ${BaseTransparentP} {
    margin: 20px 0 41px;
    max-width: 490px;
  }

  ${ImgGatsbyBackground} {
    display: none;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    padding: 50px 0 50px;

     &:nth-child(2) {
      padding-top: 20px;
     }

    ${ImgGatsbyBackground} {
      display: initial;
    }

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 36px;
    }
  }

  /*@media only screen and (min-width: 1450px) {
    .column.last {
      position: absolute;
      left: calc(50% - 100px);
    }
    padding: 40px 0 140px;
  }

  @media only screen and (min-width: 1650px) {
    .column.last {
      position: absolute;
      left: calc(50% - 100px);
    }
    padding: 40px 0 240px;
  }*/

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 981px) {
    .column {
      width: 100%;
    }

    .home-hero-image2 {
      margin-top: 0;
    }

    padding: 10px 0;
    padding-bottom: 20px;

    .column.last {
      width: 100%;
      margin-top: 10px;
    }

    ${BaseTransparentP} {
      margin: 20px 0 20px;
      max-width: 490px;
    }
  }
`;

const Intro = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgHeaderQuery {
      header: file(relativePath: { eq: "home-hero.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      header2: file(relativePath: { eq: "home_hero6.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <RelativeWrapper>
      <WidthWrapper style={{ position: 'initial' }}>
        <Wrapper>
          <div className="row">
            <div className="column">
              <h1>{intl.formatMessage({ id: "home-hero-header" })} <br /> {intl.formatMessage({ id: "home-hero-header2" })}</h1>
              <BaseTransparentP>
                {intl.formatMessage({ id: "home-hero-subheader" })}
              </BaseTransparentP>
              <a href="https://app.shipkoo.com/register" target="_blank" rel="noreferrer">
                <ArrowButton>{intl.formatMessage({ id: "home-hero-button" })}</ArrowButton>
              </a>
            </div>
            <div className="column last">
                <Img fluid={data.header.childImageSharp.fluid} alt="Shipkoo Express Shipping Partner"/>
            </div>
          </div>
        </Wrapper>
        <Wrapper>
          <div className="row">
            <div className="column">
              <div className="home-hero-image2">
                <Img fluid={data.header2.childImageSharp.fluid} alt="Shipkoo Save Time Save Money" />
              </div>
            </div>
            <div className="column last">
              <h2>{intl.formatMessage({ id: "home-hero-header3" })} <br /> {intl.formatMessage({ id: "home-hero-header4" })}</h2>
              <BaseTransparentP>
                {intl.formatMessage({ id: "home-hero-subheader2" })} <br />
                {intl.formatMessage({ id: "home-hero-subheader3" })}
              </BaseTransparentP>
              <Link to="/contact/" target="_blank">
                <ArrowButton>{intl.formatMessage({ id: "talk-to-our-experts" })}</ArrowButton>
              </Link>
            </div>
          </div>
        </Wrapper>
      </WidthWrapper>
    </RelativeWrapper>
  );
};

export default Intro;
